<template>
  <div class="detail">
    <h2>电影剧本（梗概）变更备案</h2>
    <div class="content">
      <ul>
        <li>
          <span class="label">备案立项号:</span>
          <span>{{ detail.balxh }}</span>
        </li>
        <li>
          <span class="label">影片类别:</span>
          <span>{{ detail.type }}</span>
        </li>
        <li>
          <span class="label">片名:</span>
          <span>{{ detail.filmName }}</span>
        </li>
        <li>
          <span class="label">备案单位:</span>
          <span>{{ detail.badw }}</span>
        </li>
        <li>
          <span class="label">编剧:</span>
          <span>{{ detail.bj }}</span>
        </li>
        <li>
          <span class="label">剧本（梗概）备案立项公示时间:</span>
          <span v-if="detail.gssj">{{ detail.gssj.slice(0, 7) }}</span>
        </li>
        <li>
          <span class="label">备案地:</span>
          <span>{{ detail.bad }}</span>
        </li>
        <li>
          <span class="label"></span>
          <span>{{ detail.gg }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'recordDetail',
  data() {
    return {
      detail: {},
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$axios({
        apiUrl: '/ycc/query/filmBagsByID',
        url: 'mgop.zhijiang.zytv3.ycc0query0filmBagsByID',
        method: 'GET',
        data: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        this.detail = res.result
      })
    },
  },
}
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  min-height: 100vh;
  background: #f6f7f8;
  padding: 12px 16px;
  box-sizing: border-box;
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #363a44;
    margin-bottom: 24px;
  }
  .content {
    width: 100%;
    background: #ffffff;
    border-radius: @border-radius-base;
    padding: 12px 18px;
    box-sizing: border-box;
    li {
      margin: 12px 0;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #363a44;
      }
      .label {
        color: #686b73;
      }
    }
  }
}
</style>
